import React from "react"
import styled from "styled-components"
import { MARKS, BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { up } from "styled-breakpoints"

const ContentfulModuleTerms = ({ terms }) => {

    const options = {
        renderMark: {
            [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
            [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
        },
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
            [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
            [BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
        }
    }

    return(
        <Row>
            <Text>{renderRichText(terms, options)}</Text>
        </Row>
    )

}

export default ContentfulModuleTerms

const Row = styled.section`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding-top:46px;
    padding-bottom:42px;
    background:#F4F5F6;

    ${up('md')}{
        padding-top:80px;
        padding-bottom:40px;
    }
`

const Text = styled.div`
    text-align:left;
    padding-left:24px;
    padding-right:24px;
    width:100%;
    max-width:1000px;

    a{
        color:#e40000;

        &:hover{
            color: rgb(186, 0, 0);
        }
    }
`

const H3 = styled.h3`
    font-size:28px;
    line-height: 32px;
    font-weight:normal;
    color: #323232;
    margin-top:16px;
    margin-bottom:16px;

    ${up('md')}{
        font-size:36px;
        line-height: 47px;
    }
`

const H5 = styled.h5`
    font-size:18px;
    line-height: 23px;
    font-weight:normal;
    color: #323232;
    margin-top:14px;
    margin-bottom:14px;

    ${up('md')}{
        font-size:20px;
        line-height: 25px;
    }
`

const P = styled.p`
    font-size:14px;
        line-height: 20px;
    font-weight:normal;
    color: #323232;
    margin-top:14px;
    margin-bottom:14px;
`

const Bold = styled.span`
    font-weight:bold;
`

const Italic = styled.span`
    font-style:italic;
`

const Underline = styled.span`
    text-decoration:underline;
`