import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Img from "gatsby-image/withIEPolyfill"

const ContentfulModuleSlide = ({ title, description, portraitImage, landscapeImage, badge }) => {

    return (
        <Slide>
            <Text>
                <TextBlock>
                    <Title color={'#323232'}>{title}</Title>
                    <Description color={'#323232'}>{description}</Description>
                </TextBlock>
            </Text>
            {badge && <Badge>
                <img src={badge.file.url} alt="" />
            </Badge>}
            <Image>
                <PortraitImageFluid fluid={portraitImage.fluid} />
                <LandscapeImageFluid fluid={landscapeImage.fluid} />
            </Image>
        </Slide>
    )
}

const Text = styled.div`
    display:flex;
    width:100%;
    height:100%;
    position: relative;
    z-index:2;
    flex-direction:column;
    justify-content:flex-end;
    text-align:center;

    ${up('lg')}{
        width:1000px;
        margin-left:auto;
        margin-right:auto;
        text-align:left;
    }
`

const TextBlock = styled.div`
    display:flex;
    width:100%;
    height:50%;
    flex-direction:column;
    justify-content:flex-end;
    padding:20px 30px 60px 30px;

    ${up('md')}{
        padding:20px 80px 60px 80px;
    }

    ${up('lg')}{
        width:33.333%;
        justify-content:center;
        height:100%;
        padding:0px 10px 0px 10px;
    }
`

const Title = styled.h2`
    font-size:36px;
    line-height:40px;
    font-weight:bold;
    color:${props => props.color || `white`};
    margin-top:10px;
    margin-bottom:10px;
`

const Description = styled.p`
    font-size:16px;
    line-height:21px;
    color:${props => props.color || `white`};
    margin-top:10px;
    margin-bottom:10px;
`

const Slide = styled.div`
    display:flex;
    width:100%;
    height:600px;
    background-color: black;
    position: relative;

    ${up('lg')}{
        height:380px;
    }
`

const Image = styled.div`
    display:flex;
    width:100%;
    height:100%;
    position:absolute;
    z-index:1;
    opacity:.9;
`

const PortraitImageFluid = styled(Img)`
    position: absolute;
    width:100%;
    height:100%;

    ${up('lg')}{
        display:none;
    }
`

const LandscapeImageFluid = styled(Img)`
    display:none;
    position: absolute;
    width:100%;
    height:100%;

    ${up('lg')}{
        display:block;
    }
`

const Badge = styled.div`
    position:absolute;
    right:0px;
    bottom:0px;
    z-index:2;
    width:180px;
    display:flex;
    align-items:flex-end;

    ${up('md')}{
        width:256px;
    }
    
    ${up('lg')}{
        width:400px;
    }

    img{
        width:100%;
    }
`

export default ContentfulModuleSlide