import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Meta from "../components/meta"
import Modules from "../components/contentful/modules/modules"

export default class PromoPage extends React.Component {

    createContentfulModule(__typename, i, props) {
        return (ContentfulModule => ( 
            <ContentfulModule key={i} {...props} />
        ))(Modules[__typename])
    }

    render() {

        const { meta, modules } = this.props.data.page

        return (
            <Container>
                <Meta
                    title={meta.title}
                    description={meta.description}
                />
                <Main>
                    {modules.map((module, i) => this.createContentfulModule(module.__typename, i, module))}
                </Main>
            </Container>
        )

    }

}

const Container = styled.main``

const Main = styled.main``

export const PageQuery = graphql`
    query PageQuery($slug: String!) {
        page: contentfulPage(slug: { eq: $slug }) {
            title
            slug
            meta{
                title
                description
                keywords
                image{
                    file{
                        url
                    }
                }
            }
            modules{
                ... on ContentfulModuleCarousel{
                    __typename
                    slides{
                        __typename
                        title
                        description
                        portraitImage{
                            fluid(maxWidth: 600, quality: 85) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                        }
                        landscapeImage{
                            fluid(maxWidth: 1440, quality: 85) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                        }
                        badge{
                            file{
                                url
                            }
                        }
                    }
                }
                ... on ContentfulModuleText{
                    __typename
                    content{
                        raw
                    }
                }
                ... on ContentfulModuleLottie{
                    __typename
                    title
                    width
                    height
                    json{
                        file{
                            url
                        }
                    }
                }
                ... on ContentfulModulePointsExplorer{
                    __typename
                    campaign
                    background
                    image{
                        file{
                            url
                        }
                    }
                }
                ... on ContentfulModuleTerms{
                    __typename
                    terms{
                        raw
                    }
                }
                ... on ContentfulModuleGroup{
                    items{
                        ... on ContentfulModuleIconText{
                            __typename
                            title
                            description
                            action
                            icon{
                                fluid(maxWidth: 100, quality: 100) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                            }
                        }
                        ... on ContentfulModuleImageText{
                            __typename
                            title
                            description
                            action
                            image{
                                fluid(maxWidth: 800, quality: 100) {
                                    ...GatsbyContentfulFluid_withWebp
                                }
                            }
                        }
                    }
                }
                # ... on ContentfulModuleButton{
                #             __typename
                #             copy
                #             link
                #             background
                # }
            }
        }
    }
`
