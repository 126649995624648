import ContentfulModuleCarousel from "./carousel/carousel"
import ContentfulModuleText from "./text"
import ContentfulModuleGroup from "./group/group"
import ContentfulModulePointsExplorer from "./explorer"
import ContentfulModuleTerms from "./terms"
import ContentfulModuleLottie from "./lottie"
import ContentfulModuleButton from "./moduleButton"

export default{
    ContentfulModuleCarousel,
    ContentfulModuleText,
    ContentfulModuleGroup,
    ContentfulModulePointsExplorer,
    ContentfulModuleTerms,
    ContentfulModuleLottie,
    ContentfulModuleButton,
}