import React, { useState } from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Controller } from "swiper"

import ContentfulModuleIconText from "./icon-text"
import ContentfulModuleImageText from "./image-text"

SwiperCore.use([Controller])

const ContentfulModuleGroup = ({ items }) => {

    const [activeSlide, setActiveSlide] = useState(0)
    const [swiper, setSwiper] = useState(null)
    
    return(
        <Container>
            <Row>
            {items.map((item, i) => {
                if(item.__typename === `ContentfulModuleIconText`){
                    return (
                        <ContentfulModuleIconText
                            key={i}
                            icon={item.icon}
                            title={item.title}
                            description={item.description}
                            action={item.action}
                        />
                    )
                }
                if(item.__typename === `ContentfulModuleImageText`){
                    return (
                        <ContentfulModuleImageText
                            key={i}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            action={item.action}
                        />
                    )
                }
            })}
            </Row>
            <Carousel>
                <Swiper
                    slidesPerView={1.2}
                    spaceBetween={20}
                    centeredSlides={true}
                    pagination={false}
                    onSlideChange={(slider) => setActiveSlide(slider.activeIndex)}
                    onSwiper={setSwiper} controller={{ control: swiper }}
                >
                    {items.map((item, i) => {
                        if(item.__typename === `ContentfulModuleIconText`){
                            return (
                                <SwiperSlide key={i}>
                                    <ContentfulModuleIconText
                                        icon={item.icon}
                                        title={item.title}
                                        description={item.description}
                                        action={item.action}
                                    />
                                </SwiperSlide>
                            )
                        }
                        if(item.__typename === `ContentfulModuleImageText`){
                            return (
                                <SwiperSlide key={i}>
                                    <ContentfulModuleImageText
                                        image={item.image}
                                        title={item.title}
                                        description={item.description}
                                        action={item.action}
                                    />
                                </SwiperSlide>
                            )
                        }
                    })}
                </Swiper>
                {items.length > 1 &&
                    <Pagination>
                        {items.map((item, i) => (
                            <PaginationDot
                                key={i}
                                style={{ opacity: (activeSlide === i ? `1` : `.3`) }}
                                onClick={() => swiper.slideTo(i)}
                            />
                        ))}
                    </Pagination>
                }
            </Carousel>
        </Container>
    )

}

export default ContentfulModuleGroup

const Container = styled.section`
    padding-top:80px;
    padding-bottom:40px;
    justify-content:center;

    ${up('md')}{    
        display:flex;
    }
`

const Row = styled.div`

    display:none;

    ${up('md')}{    
        flex-direction:row;
        flex-wrap:wrap;
        display:flex;
        width:100%;
    }

    ${up('lg')}{
        max-width:1000px;
        flex-wrap:nowrap;
    }
`

const Carousel = styled.div`
    position:relative;
    display:block;

    .swiper-container{
        padding-bottom:0px !important;
    }
    .swiper-pagination{
        display:none !important;
    }

    ${up('md')}{ 
        display:none;
    }
`

const Pagination = styled.div`
    display:flex;
    width:100%;
    z-index:10;
    flex-direction:row;
    justify-content:center;
    padding-top:46px;
`

const PaginationDot = styled.div`
    width:8px;
    height:8px;
    background:#323232;
    border-radius:12px;
    margin:4px;
    cursor: pointer;
`