import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { Player } from "@lottiefiles/react-lottie-player"

export default class ContentfulModuleLottie extends React.Component {

    render() {

        const { title, width, height, json } = this.props

        return (
            <Container>
                <Player
                    ref={el => (this.player = el)}
                    autoplay={true}
                    loop={true}
                    mode="normal"
                    src={`https:${json.file.url}`}
                    style={{ width: "100%", height: "100%" }}
                />
            </Container>
        )

    }

}

const Container = styled.section`
    padding-top:20px;
    padding-bottom:40px;
    justify-content:center;
    padding-left:20px;
    padding-right:20px;

    ${up('md')}{    
        display:flex;
    }
`