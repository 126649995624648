import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { Link } from "gatsby"
import Button from "../../ui/buttons/button"

const ContentfulModuleButton = ({ copy, link, background }) => {

    return (
        <Wrapper color={background}>
            <Link to={link}>
                <Button label={copy} type="flex" />
            </Link>
        </Wrapper>
    )

}

export default ContentfulModuleButton

const Wrapper = styled.div`
    text-align:center; 
    display:flex;
    width:100%;
    height:120px;
    justify-content: center;
    align-items: center;
    background-color:${props => props.color === "grey" ? `#F4F5F6` : `#ffffff`};
`
