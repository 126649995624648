import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import IframeResizer from "iframe-resizer-react"
import { InView } from 'react-intersection-observer'

export default class ContentfulModulePointsExplorer extends React.Component {

  state = {
    visible: false
  }

  render() {

    const { campaign, background, image } = this.props

    let bg = (image && image.file.url) ? image.file.url : `none`
    let url = `/campaign/${campaign}?campaign=${campaign}`
    let color = `white`

    if(background === 'WHITE') { 
      color = `white` 
    }
    
    if(background === 'GREY') { 
      color = `#f4f5f6` 
    }

    if(background === 'IMAGE') { 
      url += `&shape=true` 
    }

    return (
      <Container 
        url={bg}
        color={color}
      >
        <InView as="div" onChange={(inView, entry) => { 
            if(inView) this.setState({ visible: true }) 
          }
        }>
          {this.state.visible && 
          <IFrameResizer
            src={url}
            background="#F4F5F6"
            scrolling="no"
            bodyPadding="0px 0px 30px 0px"
          />}
        </InView>
      </Container>
    )
  }

}


const IFrameResizer = styled(IframeResizer)`
  border: none;
  width: 100%;
  height: 900px;
  overflow: hidden;
  background:transparent;
  visibility:${props => props.display};

  ${up("md")} {
    height: 850px;
  }
  
  ${up("lg")} {
    height: 850px;
  }
`

const Container = styled.section`
  background-image: url(${props => props.url});
  background-color: ${props => props.color};
  background-position:top center;
  background-size:cover;
  background-repeat:no-repeat;
  min-height: 900px;

  ${up("md")} {
    min-height: 850px;
  }
`