import React, { useState } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Controller } from "swiper"

import ContentfulModuleSlide from "./slide"

SwiperCore.use([Controller])

const ContentfulModuleCarousel = ({ slides }) => {

    const [activeSlide, setActiveSlide] = useState(0)
    const [swiper, setSwiper] = useState(null)

    return (
        <Carousel id="carousel">
            <Swiper
                slidesPerView={1}
                pagination={false}
                onSlideChange={(slider) => setActiveSlide(slider.activeIndex)}
                onSwiper={setSwiper} controller={{ control: swiper }}
            >
                {slides.map((slide, i) => (
                    <SwiperSlide key={i}>
                        <ContentfulModuleSlide
                            title={slide.title}
                            description={slide.description}
                            portraitImage={slide.portraitImage}
                            landscapeImage={slide.landscapeImage}
                            badge={slide.badge}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            {slides.length > 1 &&
                <Pagination>
                    {slides.map((slide, i) => (
                        <PaginationDot
                            key={i}
                            style={{ background: (activeSlide === i ? `white` : `transparent`) }}
                            onClick={() => swiper.slideTo(i)}
                        />
                    ))}
                </Pagination>
            }
        </Carousel>
    )

}

const Carousel = styled.section`
    position:relative;

    .swiper-container{
        padding-bottom:0px !important;
    }
    .swiper-pagination{
        display:none !important;
    }
`



const Pagination = styled.div`
    display:flex;
    width:100%;
    position: absolute;
    bottom:0px;
    z-index:10;
    flex-direction:row;
    justify-content:center;
    padding-bottom:30px;
`

const PaginationDot = styled.div`
    width:8px;
    height:8px;
    border:1px solid white;
    border-radius:12px;
    margin:4px;
    cursor: pointer;
`

export default ContentfulModuleCarousel