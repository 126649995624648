import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Img from "gatsby-image/withIEPolyfill"

const ContentfulModuleImageText = ({ title, description, action, image }) => {
    
    return(
        <Wrapper>
            <ImageWrap>
                <Image fluid={image.fluid} />
            </ImageWrap>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <Action>
                {action}
                <ArrowSVG
                    width={12}
                    height={8}
                    viewBox="0 0 12 8"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 3v1h9.192L6.828 6.364l.708.707 3.535-3.535-.353-.354L7.536 0l-.708.707L9.121 3z"
                        fill="#E40000"
                        fillRule="evenodd"
                    />
                </ArrowSVG>
            </Action>
        </Wrapper>
    )

}

export default ContentfulModuleImageText



const Title = styled.h4`
    font-size:20px;
    line-height:25px;
    font-weight:bold;
    color:#323232;
    margin-top:20px;
    margin-bottom:20px;
`

const Description = styled.p`
    font-size:16px;
    line-height:21px;
    color:#666666;
    margin-top:0px;
    margin-bottom:20px;
`

const Action = styled.a`
    font-size:18px;
    line-height: 23px;
    color:#e40000;

    &:hover{
        color: rgb(186, 0, 0);
    }
`

const ImageWrap = styled.div`
    background:black;
`

const Image = styled(Img)`
    transition: all 0.2s ease;
`

const Wrapper = styled.div`
    text-align:left; 
    display:block;
    width:100%;
    min-height:250px;

    ${up('md')}{
        display:flex;
        width:50%;
        padding-left:20px;
        padding-right:20px;
        margin-bottom:40px;
        flex-direction:column;
    }
    
    ${up('lg')}{
        flex-grow:1;
    }

    &:hover{
        ${Image}{
            opacity:.8;
        }
    }
`

const ArrowSVG = styled.svg`
    margin-left:14px;
`